@import "~@/erp/styles/variables/variables.scss";




















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.my-mail-list {
  display: flex;
  flex-direction: column;
  height: 100%;

  .icon-star1 {
    cursor: pointer;
  }

  .icon-star {
    cursor: pointer;
    color: red;

  }
}

.tab-cont {
  flex-shrink: 0;
}

.mail-drop {
  max-height: 300px;
  overflow-y: auto;
}

.email-account {
  color: #999999;
  font-size: 12px;
  margin-left: 10px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.latest-aside {
  height: 100%;
  background: #fff;
  display: flex;

  .search-container {
    flex: 1;
    overflow-y: auto;

    .search-item-header {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      background: #f5f7fa;
      font-size: 12px;
      color: #303030;
      line-height: 36px;
      cursor: pointer;

      i {
        line-height: 36px;
      }
    }

    .search-item {
      width: 100%;
      padding: 7px 20px;
      background: #fff;
      font-size: 14px;
      color: #303030;
      line-height: 22px;
      position: relative;
      cursor: pointer;
      border-bottom: 1px solid #f5f7fa;

      &::before {
        content: '';
        width: 4px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: #0486fe;
        opacity: 0;
      }

      .sendtime {
        color: #f7b500;
      }

      .top-dl {
        width: 100%;
        display: flex;
        justify-content: space-between;
        white-space: nowrap;

        .top-dt {
          display: flex;
          width: 85%;

          .mail-item-title {
            margin-left: 10px;
            font-size: 14px;
            color: #222222;
            display: inline-block;
            /* max-width: 250px; */
            overflow: hidden;
            text-overflow: ellipsis;
          }

          i {
            font-size: 12px;
            color: #0486fe;
            margin-left: 5px;
          }
        }
      }

      .bottom-dl {
        display: flex;

        .bottom-dt {
          margin-right: 20px;
          width: 16px;
          display: flex;
          flex-direction: column;

          i {
            color: #f7b500;
            font-size: 14px;
            height: 16px;
            margin-bottom: 11px;

            &.mail-icon {
              display: block;
            }

            &.looked {
              color: #d9d9d9;
            }

            &.replyed {
              color: #2eb797;
            }

            &.send {
              color: #3abc38;
            }

            &.icon-sand-clock {
              color: #0486fe;
            }
          }
        }

        .bottom-dd {
          flex: 1;
          width: 0;

          p {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            i {
              font-size: 12px;
              margin-right: 5px;

              &.file {
                color: #fa6400;
              }

              &.warning {
                color: #e02020;
              }
            }
          }

          .mail-title {
            font-weight: bold;
            color: #666666;
          }
        }
      }

      &>span {
        margin-left: 15px;
      }

      &.active {
        background: #f6faff;

        &::before {
          opacity: 1;
          transition: 0.3s 0.1s;
        }

        transition: 0.3s 0.1s;
      }

      &:hover {
        background: #f6faff;

        &::before {
          opacity: 1;
          transition: 0.3s 0.1s;
        }

        transition: 0.3s 0.1s;
      }

      .mail-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.unread {
        .mail-item-title {
          font-weight: bold;
        }
      }

      &.read {
        .mail-text {
          color: #999;
        }
      }
    }

    .label-list {
      overflow: hidden;

      li {
        float: left;
        margin-right: 10px;
        margin-top: 4px;
        display: flex;
        align-items: center;
        height: 20px;
        font-size: 12px;
        color: #fff;
        padding: 0 4px;
        border-radius: 2px;
        max-width: 100%;

        .label {
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .remove-icon {
          margin-left: 2px;
          cursor: pointer;
          opacity: 0;
        }

        &:hover {
          .remove-icon {
            opacity: 1;
          }
        }
      }
    }
  }

  .search-pagination {
    height: 40px;
    background: #fff;
    border-top: 1px solid #f5f7fa;
    display: flex;
    align-items: center;
    padding: 0 20px;

    ::v-deep .el-select {
      width: 60px;
      margin-left: 2px;

      .el-input__inner {
        padding: 0 4px;
      }

      .el-input__suffix {
        display: none;
      }
    }



    .page-current {
      display: flex;
      width: 60px;
      flex: 1;
      align-items: center;
      justify-content: center;

      .page-num {
        padding: 0 10px;
      }
    }

    .jump {
      ::v-deep .el-input {
        width: 32px;
        margin-left: 2px;
        margin-right: 2px;

        .el-input__inner {
          padding-left: 4px;
          padding-right: 4px;
          text-align: center;
        }
      }
    }
  }

  .ml-5 {
    margin-left: 5px;
  }

  i.primary {
    color: #d9d9d9 !important;
  }
}

.text-primary {
  color: #1890ff;
  opacity: .5;

  &:hover {
    opacity: 1;
    font-weight: 600;
  }
}
