@import "~@/erp/styles/variables/variables.scss";

































































































































































































::v-deep .dialog {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.dialog-content {
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 20px;
  min-height: 92px;

  .el-form {
    width: 90%;
    margin: 0 auto;
  }

  ::v-deep {
    .el-select {
      width: 100%;
    }
  }

  .error-message {
    color: #ff4d4f;
    font-size: 12px;
    margin-top: -14px;
  }
}
